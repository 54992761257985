const _is1900mDomain = /1900m/.test(document.domain)

// online 配置文件
export const config = {

  IS_TEST: false,

  BASE_URL: 'https://api-mini-holiland.1900m.com',

  P1_IMG_DOMAIN: "https://p1-static-holiland.1900m.com/",

  XCX_USER_NAME: 'gh_125106af3ab8',
  XCX_PATH: '/pages/home/home.html', 
  SHARE_IMG_URL: 'https://p1-static-holiland.1900m.com/website_fe/act_wxshare.png',
  
  // 走api-wx
  H5_WX_LOGIN_PATH: _is1900mDomain ? 
  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx55fa7c95b13e6b87&redirect_uri=${encodeURIComponent(
    'https://api-wx-holiland.1900m.com/web-api/weChat/getToken?prod=hllwx1900m'
  )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect` : 
  `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx55fa7c95b13e6b87&redirect_uri=${encodeURIComponent(
    'https://api-wx-holiland.1900m.com/web-api/weChat/getToken?prod=hllwx'
  )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`

}