//引入axios
import axios from 'axios';
import { Notify } from 'vant'
import config from '@/utils/config'

// import { ACCESS_TOKEN } from '@/store/mutation-types'
const ACCESS_TOKEN = 'accessToken' // 用户token

// 创建axios实例
const service = axios.create({
  baseURL: config.BASE_URL,
  timeout: 10000, // 请求超时时间
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  }
})


let defaultParams = {
  platform: 'h5',
  ver: '1.0',
  token: localStorage.getItem(ACCESS_TOKEN) || ''
}


function checkToken () {
  if (localStorage.getItem(ACCESS_TOKEN)) {
    defaultParams['token'] = localStorage.getItem(ACCESS_TOKEN)
  }
}

//响应拦截器即异常处理
service.interceptors.response.use(
  (res) => {
    if (res && res.data) {
      switch (res.data.code) {
        case 1: //错误
          // Notify({
          //   message: res.data.message,
          //   type: 'warning',
          //   duration: 2 * 1000
          // })
          break;
        case 2: // 登录超时
        default:
      }
    }
    return res;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "错误请求";
          break;
        case 401:
          err.message = "未授权，请重新登录";
          break;
        case 403:
          err.message = "拒绝访问";
          break;
        case 505:
          err.message = "http版本不支持该请求";
          break;
        default:
          err.message = `连接错误${err.response.status}`;
      }
    } else {
      err.message = "连接到服务器失败";
    }
    return Promise.resolve(err.response);
  }
);

service.get = function (url, param) {
  checkToken()
  return new Promise((resolve, reject) => {
    service({
      method: "get",
      url,
      params: { ...param, ...defaultParams },
    }).then(res => {
      if (res && res.status && res.status === 0) {
        resolve(res.data);
      } else {
        Notify({
          message: res.message,
          type: 'warning',
          duration: 2 * 1000
        })
        reject(res);
      }
    }).catch(err => {
      reject(err);
    });
  });
},
service.getBlob = function (url, param) {
  checkToken()
  return new Promise((resolve, reject) => {
    return service({
      url,
      method: 'GET',
      params: { ...param, ...defaultParams },
      responseType: 'blob',
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    });
  });
},
//post请求
service.post = function (url, param) {
  return new Promise((resolve, reject) => {
    checkToken()
    service({
      method: "post",
      url,
      data: { ...param, ...defaultParams },
      
    }).then(res => {
      if(res){
        resolve(res.data);
      }else{
        Notify({
          message: '网络错误',
          type: 'warning',
          duration: 2 * 1000
        })
        reject();
      }
    }).catch(err => {
      Notify({
        message: err.message,
        type: 'warning',
        duration: 2 * 1000
      })
      reject(err);
    });
  });
};

service.openurl = function (url, param) {
  checkToken()

  let p = {
    ...param,
    ...defaultParams
  }
  let list = [] 
  for(let k in p){
    list.push(k+"="+encodeURIComponent(p[k]))
  }
  window.open(config.BASE_URL + url + (list.length ? "?"+list.join("&") : ""), "_blank")
}

export default service;
